<template>
  <div id="exam-result-screen">
    <hr style="height: 10px" />

    <div v-if="loading" class="d-flex justify-content-center mb-1">
      <b-spinner label="Loading..." class="mt-4" />
    </div>
    <div v-else style="margin-bottom: 20px">
      <div style="display: flex">
        <div>
          <h4 class="text-primary" style="margin: 10px">
            {{ toTitleCase(userResult.name) }}
            {{ toTitleCase(userResult.surname) }}
          </h4>
        </div>
        <div style="flex-grow: 1" />
      </div>
      <div title="" class="mb-1" style="min-height: 360px">
        <b-row class="match-height">
          <b-col lg="6" class="px-1 mt-2">
            <div style="display: flex; margin: 0px 1%">
              <div style="flex-grow: 1">
                <p>
                  <span style="font-size: 1.2rem; font-weight: 600"
                    >Temel Bilimler</span
                  >
                  <span> ({{ getBasicPartDurationText() }}) </span>
                </p>
              </div>
              <p class="text-primary font-weight-bold">
                {{ getTotalSubjectNet(getSubjectResults(0)) }} Net
              </p>
              <div style="min-width: 80px; text-align: right">
                ({{ userResult.basic_question_count }} Soru)
              </div>
            </div>
            <div
              v-for="(subject, index) in getSubjectResults(0)"
              :key="index"
              class="progress-wrapper"
              style="margin: 10px 1%"
            >
              <div style="display: flex">
                <b-card-text class="mb-0 font-weight-bold" style="flex-grow: 1">
                  {{ toTitleCase(subject.name) }}:
                  {{ subject.correct_count + "D," }}
                  {{ subject.wrong_count + "Y," }}
                  {{
                    subject.question_count -
                    (Number(subject.correct_count + "") +
                      Number(subject.wrong_count + "")) +
                    "B"
                  }}
                </b-card-text>

                <div class="text-primary font-weight-bold">
                  {{ subject.net }}
                  Net
                </div>
                <div style="min-width: 80px; text-align: right">
                  ({{ subject.question_count }}
                  Soru)
                </div>
              </div>
              <b-progress
                v-model="subject.net_percentage"
                class="progress-bar-primary"
                max="100"
              >
              </b-progress>
            </div>
          </b-col>

          <b-col lg="6" class="px-1 mt-2">
            <div style="display: flex; margin: 0px 1%">
              <div style="flex-grow: 1">
                <p>
                  <span style="font-size: 1.2rem; font-weight: 600"
                    >Klinik Bilimler</span
                  >
                  <span> ({{ getClinicalPartDurationText() }}) </span>
                </p>
              </div>
              <p class="text-primary font-weight-bold">
                {{ getTotalSubjectNet(getSubjectResults(1)) }} Net
              </p>
              <div style="min-width: 80px; text-align: right">
                ({{ userResult.clinical_question_count }} Soru)
              </div>
            </div>

            <div
              v-for="(subject, index) in getSubjectResults(1)"
              :key="index"
              class="progress-wrapper"
              style="margin: 10px"
            >
              <div style="display: flex">
                <b-card-text class="mb-0 font-weight-bold" style="flex-grow: 1">
                  {{ toTitleCase(subject.name) }}:

                  {{ subject.correct_count + "D," }}
                  {{ subject.wrong_count + "Y," }}
                  {{
                    subject.question_count -
                    (Number(subject.correct_count + "") +
                      Number(subject.wrong_count + "")) +
                    "B"
                  }}
                </b-card-text>
                <div class="text-primary font-weight-bold">
                  {{ subject.net }}
                  Net
                </div>
                <div style="min-width: 80px; text-align: right">
                  ({{ subject.question_count }}
                  Soru)
                </div>
              </div>
              <b-progress
                v-model="subject.net_percentage"
                class="progress-bar-warning"
                max="100"
              >
              </b-progress>
            </div>
          </b-col>
        </b-row>
      </div>

      <hr />

      <b-row class="mt-3 match-height">
        <b-col :lg="showQuestionList ? 6 : 12" class="px-1">
          <div style="padding: 1% 1%">
            <div style="height: 540px; overflow: scroll; padding-right: 10px">
              <div v-if="selectedQuestion">
                <div
                  v-html="getSelectedQuestionText()"
                  style="margin-bottom: 20px; font-size: 1.15rem"
                ></div>

                <div
                  v-for="(option, index) in options"
                  :key="index"
                  style="margin-top: 10px; display: table"
                >
                  <b-button
                    :variant="getOptionButtonClass(selectedQuestion, option)"
                    class="rounded-circle"
                    style="
                      padding: 6px;
                      height: 26px;
                      width: 26px;
                      margin-right: 12px;
                      display: table-cell;
                    "
                    size="sm"
                  >
                    {{ option }}
                  </b-button>
                  <div style="display: table-cell; width: 100%">
                    <p
                      :class="getOptionTextClass(selectedQuestion, option)"
                      style="font-size: 1.1rem; margin-bottom: 0px"
                    >
                      {{ getSelectedQuestionOptionText(option) }}
                    </p>
                    <div style="display: flex">
                      <b-progress
                        v-if="selectedQuestion.distributionLoaded"
                        style="
                          flex-grow: 1;
                          margin-top: 10px;
                          height: 3px !important;
                        "
                        v-model="
                          getOptionSelectionDistributionPercentage(option).value
                        "
                        class="progress-bar-primary"
                        max="100"
                      >
                      </b-progress>
                      <div v-else style="flex-grow: 1; height: 14px"></div>
                      <div style="width: 50px; text-align: center">
                        <p style="font-size: 0.95rem; margin: 0 auto">
                          %{{
                            selectedQuestion.distributionLoaded
                              ? getOptionSelectionDistributionPercentage(option)
                                  .value
                              : ""
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <b-alert variant="secondary" show style="margin-top: 15px">
                  <div class="alert-body" style="padding: 3px 2px 3px 15px">
                    <div style="display: flex">
                      <p style="flex-grow: 1">Boş bırakanlar</p>
                      <div style="width: 50px; text-align: center">
                        <p style="font-size: 0.95rem; margin: 0 auto">
                          %{{ getSelectionQuestionEmptyPercentage() }}
                        </p>
                      </div>
                    </div>
                  </div>
                </b-alert>
              </div>

              <div v-if="selectedQuestion" class="mt-2" style="display: flex">
                <div style="flex-grow: 1">
                  <p style="margin-bottom: 0; font-weight: 500">
                    {{ selectedQuestion.title }}
                  </p>
                  <p style="font-weight: 500">
                    {{ toTitleCase(selectedQuestion.subtitle) }}
                  </p>
                </div>

                <div style="flex-grow: 1">
                  <div style="display: block; float: right">
                    <p
                      class="text-primary"
                      style="
                        margin-bottom: 5px;
                        margin-left: 2px;
                        font-weight: 600;
                      "
                    >
                      Cevap: {{ selectedQuestion.answer }}
                    </p>
                    <b-badge
                      v-if="!selectedQuestion.user_answer"
                      variant="light-secondary"
                    >
                      Boş
                    </b-badge>
                    <b-badge
                      v-else-if="
                        selectedQuestion.user_answer == selectedQuestion.answer
                      "
                      variant="light-success"
                    >
                      Doğru
                    </b-badge>
                    <b-badge
                      v-else
                      variant="light-danger"
                      style="padding: 5px 15px"
                    >
                      Yanlış
                    </b-badge>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-2" style="display: flex">
              <div>
                <b-button
                  :variant="
                    selectedQuestionIndex <= 0
                      ? 'outline-secondary'
                      : 'outline-primary'
                  "
                  size="sm"
                  :disabled="selectedQuestionIndex <= 0"
                  @click="setSelectedQuestion(selectedQuestionIndex - 1)"
                >
                  <feather-icon icon="ChevronLeftIcon" class="mr-50" />
                  <span class="align-middle">Önceki</span>
                </b-button>
              </div>
              <div
                style="
                  flex-grow: 1;
                  font-weight: 600;
                  text-align: center;
                  margin-top: 5px;
                "
              >
                {{ selectedQuestionIndex + 1 }} /
                {{ questions.length }}
              </div>
              <b-button
                :variant="
                  selectedQuestionIndex == questions.length - 1
                    ? 'outline-secondary'
                    : 'outline-primary'
                "
                size="sm"
                :disabled="selectedQuestionIndex == questions.length - 1"
                @click="setSelectedQuestion(selectedQuestionIndex + 1)"
              >
                <span class="align-middle">Sonraki</span>
                <feather-icon icon="ChevronRightIcon" class="ml-50" />
              </b-button>
            </div>

            <hr v-if="showQuestionList == false" style="margin: 20px 5px" />
            <div
              v-if="showQuestionList == false"
              class="mt-1"
              style="display: flex"
            >
              <div style="flex-grow: 1"></div>
              <b-button
                :variant="'outline-primary'"
                size="sm"
                pill
                @click="showQuestionList = true"
              >
                <feather-icon icon="AlignLeftIcon" class="mr-50" />
                <span class="align-middle">Tüm Soruları Göster</span>
              </b-button>
            </div>
          </div>
        </b-col>
        <b-col v-if="showQuestionList" lg="6" class="px-1">
          <div>
            <div style="display: flex; margin-bottom: 15px">
              <h5 style="flex-grow: 1">Tüm Sorular</h5>
              <b-button
                :variant="'outline-primary'"
                size="sm"
                pill
                @click="showQuestionList = false"
              >
                <span class="align-middle">Gizle</span>
                <feather-icon icon="XIcon" class="ml-50" />
              </b-button>
            </div>

            <div
              style="
                height: 500px;
                overflow: scroll;
                max-width: 100%;
                overflow-x: hidden;
              "
            >
              <b-list-group flush>
                <b-list-group-item
                  v-for="(question, index) in questions"
                  :key="index"
                  :id="'q_' + index"
                  @click="selectedQuestionIndex = index"
                  :variant="selectedQuestionIndex == index ? 'primary' : ''"
                  style="cursor: pointer"
                >
                  <table id="all-questions">
                    <tr>
                      <td>
                        <div style="min-width: 26px">{{ index + 1 }}</div>
                      </td>
                      <td style="padding-left: 5px; padding-right: 10px">
                        <div style="width: 60px">
                          <b-badge
                            v-if="!question.user_answer"
                            variant="light-secondary"
                          >
                            Boş
                          </b-badge>
                          <b-badge
                            v-else-if="question.user_answer == question.answer"
                            variant="light-success"
                          >
                            Doğru
                          </b-badge>
                          <b-badge v-else variant="light-danger">
                            Yanlış
                          </b-badge>
                        </div>
                      </td>
                      <td>
                        <div style="margin-right: 10px; width: 80px">
                          <p
                            style="
                              font-weight: 420;
                              margin: 0;
                              white-space: nowrap;
                              overflow: hidden;
                              text-overflow: hidden;
                            "
                          >
                            {{
                              toTitleCase(
                                question.title.length > 9
                                  ? question.title.substring(0, 8) + "."
                                  : question.title
                              )
                            }}
                          </p>
                        </div>
                      </td>
                      <td style="flex-grow: 1">
                        <p
                          style="
                            margin: 0;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: hidden;
                            font-size: 0.97rem;
                          "
                        >
                          <img
                            v-if="question.is_favorite"
                            src="@/assets/images/star_filled.svg"
                            width="15"
                            height="15"
                            style="margin-right: 5px"
                          />
                          {{ question.question_text }}
                        </p>
                      </td>
                    </tr>
                  </table>
                </b-list-group-item>
              </b-list-group>
            </div>
            <div v-if="questions" class="mt-1" style="display: flex">
              <div style="flex-grow: 1">
                <p style="font-weight: 600; margin-bottom: 0; margin-top: 10px">
                  Cevaplanan: {{ answeredQuestionCount }} /
                  {{ questions.length }}
                </p>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>

    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BSpinner,
  BButton,
  BAlert,
  BBadge,
  BRow,
  BCol,
  BProgress,
  BTable,
  BListGroup,
  BListGroupItem,
  BTabs,
  BTab,
  BPagination,
  BButtonGroup,
  BFormGroup,
} from "bootstrap-vue";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";

export default {
  components: {
    BCard,
    BCardText,
    BSpinner,
    BButton,
    BAlert,
    BBadge,
    BRow,
    BCol,
    BProgress,
    BTable,
    BListGroup,
    BListGroupItem,
    BTabs,
    BTab,
    BPagination,
    BButtonGroup,
    BFormGroup,
    vSelect,
  },

  props: {
    userResult: null,
    trialExamId: null,
  },

  data() {
    return {
      loading: true,
      questions: [],
      selectedQuestionIndex: -1,
      options: ["A", "B", "C", "D", "E"],
      showQuestionList: true,
      sortByClinicalScore: true,
      selectedTargetExams: [],
    };
  },

  computed: {
    selectedQuestion() {
      if (
        this.selectedQuestionIndex < 0 ||
        !this.questions ||
        this.selectedQuestionIndex > this.questions.length
      ) {
        return null;
      }
      return this.questions[this.selectedQuestionIndex];
    },
    answeredQuestionCount() {
      var count = 0;
      if (this.questions) {
        this.questions.forEach((question) => {
          if (question.user_answer) {
            count++;
          }
        });
      }
      return count;
    },
  },

  watch: {
    selectedQuestionIndex: async function (newValue) {
      var question = this.questions[this.selectedQuestionIndex];
      if (!question.distributionLoaded) {
        question = await store.dispatch(
          "trialExams/loadQuestionsUserAnswerDistribution",
          question
        );
        this.$forceUpdate();
      }
    },
  },

  methods: {

    getOptionSelectionDistributionPercentage(option) {
      var question = this.selectedQuestion;
      if (question && question.distributionLoaded) {
        var total = question.participant_count;
        if (total > 0) {
          switch (option) {
            case "A":
              return { value: Math.round((100 * question.a_count) / total) };
            case "B":
              return { value: Math.round((100 * question.b_count) / total) };
            case "C":
              return { value: Math.round((100 * question.c_count) / total) };
            case "D":
              return { value: Math.round((100 * question.d_count) / total) };
            case "E":
              return { value: Math.round((100 * question.e_count) / total) };
          }
        }
      }
      return { value: 0 };
    },
    getSelectionQuestionEmptyPercentage() {
      var question = this.selectedQuestion;
      if (question && question.distributionLoaded) {
        var total = question.participant_count;
        var empty =
          total -
          (question.a_count +
            question.b_count +
            question.c_count +
            question.d_count +
            question.e_count);
        return Math.round((100 * empty) / total);
      }
      return "";
    },

    closeTrialExamUserResult() {
      this.$emit("closeTrialExamUserResult");
    },

    getOptionButtonClass(selectedQuestion, option) {
      if (
        selectedQuestion.user_answer &&
        selectedQuestion.user_answer == option
      ) {
        return selectedQuestion.answer == option ? "success" : "outline-danger";
      } else if (selectedQuestion.answer == option) {
        return "outline-primary";
      }
      return "outline-secondary";
    },

    getOptionTextClass(selectedQuestion, option) {
      if (
        selectedQuestion.user_answer &&
        selectedQuestion.user_answer == option
      ) {
        return selectedQuestion.answer == option
          ? "font-weight-bolder text-success"
          : "font-weight-bolder text-danger";
      } else if (selectedQuestion.answer == option) {
        return "font-weight-bolder text-primary";
      }
      return "";
    },

    isExamTimeFinished() {
      return this.examFinishTime && new Date() > this.examFinishTime;
    },
    getRemainingTimeText() {
      if (this.examFinishTime) {
        if (this.isExamTimeFinished()) {
          return "Sınav Bitti";
        }
        const secondDiff = Math.round(
          Math.abs(this.examFinishTime - new Date()) / 1000
        );
        var hours = Math.floor(secondDiff / 3600);
        var minutes = Math.floor((secondDiff % 3600) / 60);
        var seconds = secondDiff % 60;
        return (
          "0" +
          hours +
          ":" +
          (minutes < 10 ? "0" + minutes : minutes) +
          ":" +
          (seconds < 10 ? "0" + seconds : seconds)
        );
      }
      return "--";
    },
    setSelectedQuestion(index) {
      if (index >= 0 && index < this.questions.length) {
        this.selectedQuestionIndex = index;
        /*
        var width = document.body.clientWidth;
        if (width && width > 992) {
          document.getElementById("q_" + index).scrollIntoView();
        }
        */
      }
    },

    getSelectedQuestionOptionText(option) {
      var question = this.selectedQuestion;
      if (question && option) {
        switch (option) {
          case "A":
            return question.option_a;
          case "B":
            return question.option_b;
          case "C":
            return question.option_c;
          case "D":
            return question.option_d;
          case "E":
            return question.option_e;
        }
      }
      return "-";
    },

    getSelectedQuestionText() {
      if (!this.selectedQuestion) {
        return "Error!";
      }
      var text = this.selectedQuestion.question_text;
      if (text.toLocaleLowerCase("tr-TR").includes("script")) {
        return "Error!";
      }
      return (
        "<span style='font-weight: 600; color: #46b4ab;'>Soru " +
        (this.selectedQuestionIndex + 1) +
        ": </span>" +
        text
          .replaceAll("[[", "<img src='")
          .replaceAll("\n", "<br/>")
          .replaceAll(
            "]]",
            "' style='margin: 0px auto; margin-top: 10px; margin-bottom: 10px; max-width: 96%; display: block; max-height: 640px;'/>"
          )
      );
    },

    toTitleCase(str) {
      return !str
        ? ""
        : str
            .toLocaleLowerCase("tr-TR")
            .replace(
              /(?:^|\s|,|;|!|:|-|\.|\?)[a-z0-9ğçşüöı]/g,
              function (match) {
                return match.toLocaleUpperCase("tr-TR");
              }
            );
    },

    getSubjectResults(isClinical) {
      var subjects = [];
      this.questions.forEach((question) => {
        if (question.clinical == isClinical) {
          var subject;
          subjects.forEach((sbj) => {
            if (!subject && sbj.name == question.title) {
              subject = sbj;
            }
          });
          if (!subject) {
            subjects.push({
              name: question.title,
              correct_count: 0,
              wrong_count: 0,
              question_count: 0,
              is_clinical: question.clinical,
            });
          }
        }
      });
      this.questions.forEach((question) => {
        if (question.clinical == isClinical) {
          subjects.forEach((sbj) => {
            if (sbj.name == question.title) {
              if (question.user_answer == question.answer) {
                sbj.correct_count++;
              } else if (question.user_answer) {
                sbj.wrong_count++;
              }
              sbj.question_count++;
            }
          });
        }
      });
      subjects.forEach((sbj) => {
        sbj.net = sbj.correct_count - 0.25 * sbj.wrong_count;
        sbj.net_percentage = Math.round((100 * sbj.net) / sbj.question_count);
      });
      return subjects;
    },

    getSubjectCode(subject) {
      subject.toLocaleUpperCase();
      switch (subject) {
        case "ANATOMİ":
          return "an";
        case "FİZYOLOJİ":
          return "fz";
        case "BİYOKİMYA":
          return "bk";
        case "MİKROBİYOLOJİ":
          return "mb";
        case "PATOLOJİ":
          return "pt";
        case "FARMAKOLOJİ":
          return "fr";
        case "DAHİLİYE":
          return "dh";
        case "PEDİATRİ":
          return "pd";
        case "GENEL CERRAHİ":
          return "gc";
        case "KADIN DOĞUM":
          return "kd";
        case "KÜÇÜK STAJLAR":
          return "ks";
      }
      return "";
    },

    getOrderInfoText() {
      var adminObject = JSON.parse(localStorage.getItem("adminObject"));
      var myId = adminObject.a_id;
      var myResult;
      var filteredResults = this.getFilteredUserResults();
      filteredResults.forEach((result) => {
        if (result.user_id == myId) {
          myResult = result;
        }
      });
      if (myResult) {
        var tOrder = 1;
        var kOrder = 1;
        filteredResults.forEach((result) => {
          if (result.t_point > myResult.t_point) {
            tOrder++;
          }
          if (result.k_point > myResult.k_point) {
            kOrder++;
          }
        });
        return "Temel Sıranız: " + tOrder + ", Klinik Sıranız: " + kOrder;
      }
      return "Filtrelenen sonuçlar arasında değilsiniz";
    },

    getTotalSubjectNet(subjects) {
      if (subjects && subjects.length > 0) {
        var self = this;
        var totalNet = 0;
        subjects.forEach((sbj) => {
          totalNet += sbj.net;
        });
        return Math.round(totalNet * 100) / 100;
      }
      return "";
    },

    getBasicPartDurationText() {
      if (
        this.userResult &&
        this.userResult.basic_start_time &&
        this.userResult.basic_end_time
      ) {
        const secondDiff = Math.round(
          Math.abs(
            new Date(this.userResult.basic_end_time.replace(/-/g, "/")) -
              new Date(this.userResult.basic_start_time.replace(/-/g, "/"))
          ) / 1000
        );
        return Math.round(secondDiff / 60) + "dk " + (secondDiff % 60) + "s";
      }
      return "-";
    },

    getClinicalNet() {
      if (this.userResult) {
        return (
          this.userResult.clinical_correct_count -
          0.25 * this.userResult.clinical_wrong_count
        );
      }
      return "";
    },

    getClinicalPartDurationText() {
      if (
        this.userResult &&
        this.userResult.clinical_start_time &&
        this.userResult.clinical_end_time
      ) {
        const secondDiff = Math.round(
          Math.abs(
            new Date(this.userResult.clinical_end_time.replace(/-/g, "/")) -
              new Date(this.userResult.clinical_start_time.replace(/-/g, "/"))
          ) / 1000
        );
        return Math.round(secondDiff / 60) + "dk " + (secondDiff % 60) + "s";
      }
      return "-";
    },

    getSubjectIndex(subject) {
      switch (subject) {
        case "ANATOMİ":
          return 1;
        case "FİZYOLOJİ":
          return 2;
        case "BİYOKİMYA":
          return 3;
        case "MİKROBİYOLOJİ":
          return 4;
        case "PATOLOJİ":
          return 5;
        case "FARMAKOLOJİ":
          return 6;
        case "DAHİLİYE":
          return 7;
        case "PEDİATRİ":
          return 8;
        case "GENEL CERRAHİ":
          return 9;
        case "KADIN DOĞUM":
          return 10;
        case "KÜÇÜK STAJLAR":
          return 11;
      }
      return 0;
    },
    filterBy(option, label, search) {
      return (
        (label || "")
          .toLocaleLowerCase("tr-TR")
          .indexOf(search.toLocaleLowerCase("tr-TR")) > -1
      );
    },
  },

  async created() {
    var payload = {
      trialExamId: this.trialExamId,
      adminId: this.userResult.user_id,
    };
    this.questions = await store.dispatch(
      "examsAdmin/getUserTrialExamResultQuestions",
      payload
    );

    var self = this;

    if (this.questions) {
      this.selectedQuestionIndex = 0;
      this.questions.sort(function (r1, r2) {
        return self.getSubjectIndex(r1.title) - self.getSubjectIndex(r2.title);
      });
    }

    this.loading = false;

    document.onkeydown = checkKey;
    function checkKey(e) {
      e = e || window.event;
      if ((e.keyCode == "37" || e.keyCode == "38") &&  self.selectedQuestionIndex > 0) {
        self.setSelectedQuestion(self.selectedQuestionIndex - 1);
      } else if (
        (e.keyCode == "39" || e.keyCode == "40") && 
        self.selectedQuestionIndex < self.questions.length - 1
      ) {
        self.setSelectedQuestion(self.selectedQuestionIndex + 1);
      }
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.list-group-item {
  padding: 0.65rem 1rem;
}
#exam-result-screen .progress {
  height: 0.28rem !important;
}

#exam-result-screen .table th,
#exam-result-screen .table td {
  padding: 0.5rem 2rem;
}

#all-questions td {
  background-color: transparent !important;
}
</style>
