<template>
  <div>
    <div v-if="loading" class="d-flex justify-content-center mb-1">
      <b-spinner label="Loading..." />
    </div>

    <div v-else style="margin-bottom: 150px">
      <div v-if="lessonExamForResults">
        <b-button
          size="sm"
          class="mb-1"
          variant="outline-primary"
          @click="lessonExamForResults = null"
        >
          <feather-icon icon="ArrowLeftIcon" class="mr-50" />
          <span class="align-middle">Sınavlara Dön</span>
        </b-button>
        <lesson-exam-result
          :lessonExam="lessonExamForResults"
        ></lesson-exam-result>
      </div>
      <div v-else>
        <b-card :title="'Ders Denemeleri (' + lessonExams.length + ')'">
          <b-form-input placeholder="Ders ara" v-model="lessonExamsFilter" />
          <b-table
            responsive
            selectable
            hover
            :per-page="examsPerPage"
            :current-page="examsCurrentPage"
            select-mode="single"
            :items="filteredLessonExams"
            :fields="lessonExamsTableFields"
            @row-selected="onLessonExamSelected"
            style="min-height: 250px; margin-top: 15px"
          >
            <template #cell(title)="data">
              {{ toTitleCase(data.item.title) }}
            </template>

            <template #cell(question_count)="data">
              {{ data.item.current_question_count }} / {{ data.value }}
            </template>

            <template #cell(published)="data">
              <div :style="data.value == 1 ? 'color: green;' : 'color:red;'">
                {{ data.value == 1 ? "Yayınlandı" : "Yayında Değil" }}
              </div>
            </template>

            <template #cell(participant_count)="data">
              <p
                style="
                  font-weight: 700;
                  max-width: 80px;
                  text-decoration: underline;
                  margin: 0px;
                "
                class="text-primary"
                @click="showExamResult(data)"
              >
                {{ data.item.participant_count }} kişi
              </p>
            </template>

            <template #cell(creation_time)="data">
              <p style="margin-bottom: 0px">
                {{ dateTimeToDayText(data.item.creation_time) }}
              </p>
            </template>

            <template #cell(action)="data">
              <b-button
                variant="outline-danger"
                @click="showDeleteLessonExamModal(data.item)"
                size="sm"
                style="float: right"
              >
                Sil
              </b-button>
              <b-button
                variant="outline-primary"
                @click="showUpdateLessonExamModal(data.item)"
                class="mx-1"
                size="sm"
                style="float: right"
              >
                Güncelle
              </b-button>
            </template>
          </b-table>

          <div style="display: flex">
            <div style="width: 100px" />
            <div style="flex-grow: 1">
              <b-pagination
                v-if="filteredLessonExams.length > examsPerPage"
                v-model="examsCurrentPage"
                :total-rows="filteredLessonExams.length"
                :per-page="examsPerPage"
                :align="'center'"
                style="margin-bottom: 0px"
              />
            </div>

            <b-button
              variant="primary"
              @click="showAddLessonExamModal()"
              class="mx-1"
              size="sm"
              style="float: right"
            >
              Ders Denemesi Ekle
            </b-button>
          </div>
        </b-card>

        <b-row class="match-height">
          <b-col v-if="selectedLessonExam" md="6">
            <b-card
              v-if="selectedLessonExam"
              :title="
                toTitleCase(selectedLessonExam.title) +
                ' ' +
                selectedLessonExam.exam_order +
                '. Deneme Soruları (' +
                questionsOfExam.length +
                ')'
              "
            >
              <b-table
                ref="examQuestionsTable"
                responsive
                selectable
                hover
                class="mt-2"
                :per-page="questionsPerPage"
                :current-page="questionsOfExamCurrentPage"
                :items="questionsOfExam"
                :fields="questionsOfExamTableFields"
                select-mode="single"
                @row-selected="onQuestionSelected"
              >
                <template #cell(title)="data">
                  {{ toTitleCase(data.value) }}
                </template>
                <template #cell(order_in_exam)="data">
                  <div style="padding-left: 12px">
                    {{ data.value }}
                  </div>
                  <b-badge v-if="data.item.canceled" variant="warning">
                    İptal
                  </b-badge>
                </template>
                <template #cell(subtitle)="data">
                  {{ toTitleCase(data.value) }}
                </template>
                <template #cell(question_text)="data">
                  <p class="max-two-lines" style="margin-bottom: 0px">
                    {{ data.value }}
                  </p>
                </template>
              </b-table>

              <div class="mt-1">
                <b-pagination
                  v-if="questionsOfExam.length > questionsPerPage"
                  v-model="questionsOfExamCurrentPage"
                  :total-rows="questionsOfExam.length"
                  :per-page="questionsPerPage"
                  :align="'center'"
                />
              </div>
            </b-card>
          </b-col>

          <b-col :md="selectedLessonExam ? 6 : 12">
            <b-card :title="'Soru Havuzu (' + poolQuestions.length + ')'">
              <b-row>
                <b-col md="6" class="mb-1">
                  <v-select
                    v-model="selectedSubject"
                    :clearable="false"
                    :options="subjectList"
                    label="title"
                  >
                    <template #option="data">
                      <span> {{ data.title }}</span>
                      <span>
                        ({{ getTitleQuestionCount(data.title_id) }} Soru)</span
                      >
                    </template>
                  </v-select>
                </b-col>

                <b-col md="6" class="mb-1">
                  <b-form-input
                    v-model="questionFilter"
                    placeholder="Soru içeriği filtreleyin"
                    autocomplete="off"
                  />
                </b-col>
              </b-row>

              <b-table
                ref="poolQuestionsTable"
                responsive
                selectable
                hover
                :per-page="questionsPerPage"
                :current-page="questionsCurrentPage"
                :items="questionsInTable"
                :fields="questionPoolTableFields"
                style="min-height: 160px"
                select-mode="single"
                @row-selected="onQuestionSelected"
              >
                <template #cell(title)="data">
                  {{ toTitleCase(data.value) }}
                </template>
                <template #cell(subtitle)="data">
                  {{ toTitleCase(data.value) }}
                </template>
                <template #cell(question_text)="data">
                  <p class="max-two-lines" style="margin-bottom: 0px">
                    {{ data.value }}
                  </p>
                </template>
                <template #cell(action)="data">
                  <b-button
                    v-if="
                      selectedLessonExam &&
                      selectedLessonExam.title_id == data.item.title_id
                    "
                    variant="outline-primary"
                    @click="transferQuestionToLessonExamFromPool(data.item)"
                    size="sm"
                    style="min-width: 80px"
                  >
                    Denemeye Aktar
                  </b-button>
                </template>
              </b-table>

              <div class="mt-1">
                <b-pagination
                  v-if="questionsInTable.length > questionsPerPage"
                  v-model="questionsCurrentPage"
                  :total-rows="questionsInTable.length"
                  :per-page="questionsPerPage"
                  :align="'center'"
                />
              </div>
            </b-card>
          </b-col>
        </b-row>

        <b-card style="min-height: 240px">
          <div class="mt-1" style="display: flex">
            <h4 class="flex-grow-1">
              {{ selectedQuestion ? "Soruyu Düzenle" : "Yeni Soru Ekle" }}
            </h4>
            <b-button
              v-if="selectedQuestion"
              variant="outline-secondary"
              @click="cancelQuestionEdit()"
              size="sm"
              style="float: right"
            >
              Düzenlemeyi Bitir
            </b-button>
          </div>
          <div>
            <b-row>
              <b-col xl="6" cols="12">
                <label for="question-text">Soru Metni:</label>
                <b-form-textarea
                  id="question-text"
                  placeholder="Soru metnini yazın"
                  rows="2"
                  max-rows="8"
                  class="mb-1 mb-xl-0"
                  v-model="questionText"
                />

                <div class="mt-1" style="display: flex">
                  <div class="mr-2">
                    <b-button
                      :variant="answer == 'A' ? 'primary' : 'outline-secondary'"
                      @click="answer = 'A'"
                      class="btn-icon rounded-circle"
                    >
                      A
                    </b-button>
                  </div>
                  <div style="width: 100%">
                    <b-form-input
                      v-model="optionA"
                      placeholder=""
                      autocomplete="off"
                    />
                  </div>
                </div>

                <div class="mt-1" style="display: flex">
                  <div class="mr-2">
                    <b-button
                      :variant="answer == 'B' ? 'primary' : 'outline-secondary'"
                      @click="answer = 'B'"
                      class="btn-icon rounded-circle"
                    >
                      B
                    </b-button>
                  </div>
                  <div style="width: 100%">
                    <b-form-input
                      v-model="optionB"
                      placeholder=""
                      autocomplete="off"
                    />
                  </div>
                </div>

                <div class="mt-1" style="display: flex">
                  <div class="mr-2">
                    <b-button
                      :variant="answer == 'C' ? 'primary' : 'outline-secondary'"
                      @click="answer = 'C'"
                      class="btn-icon rounded-circle"
                    >
                      C
                    </b-button>
                  </div>
                  <div style="width: 100%">
                    <b-form-input
                      v-model="optionC"
                      placeholder=""
                      autocomplete="off"
                    />
                  </div>
                </div>

                <div class="mt-1" style="display: flex">
                  <div class="mr-2">
                    <b-button
                      :variant="answer == 'D' ? 'primary' : 'outline-secondary'"
                      @click="answer = 'D'"
                      class="btn-icon rounded-circle"
                    >
                      D
                    </b-button>
                  </div>
                  <div style="width: 100%">
                    <b-form-input
                      v-model="optionD"
                      placeholder=""
                      autocomplete="off"
                    />
                  </div>
                </div>

                <div class="mt-1" style="display: flex">
                  <div class="mr-2">
                    <b-button
                      :variant="answer == 'E' ? 'primary' : 'outline-secondary'"
                      @click="answer = 'E'"
                      class="btn-icon rounded-circle"
                    >
                      E
                    </b-button>
                  </div>
                  <div style="width: 100%">
                    <b-form-input
                      v-model="optionE"
                      placeholder=""
                      autocomplete="off"
                    />
                  </div>
                </div>

                <div class="mt-1" style="display: flex">
                  <div class="mr-2" style="width: 100px; margin-top: 8px">
                    Alan Seçin:
                  </div>
                  <div style="width: 100%">
                    <b-form-group>
                      <v-select
                        v-model="selectedFieldText"
                        :options="tusFieldOptions"
                        :filterBy="filterBy"
                      />
                    </b-form-group>
                  </div>
                </div>

                <label class="mt-2" for="solution-text">Çözüm/Açıklama:</label>
                <b-form-textarea
                  id="solution-text"
                  placeholder="Soru metnini yazın"
                  rows="8"
                  max-rows="15"
                  class="mb-1 mb-xl-0"
                  v-model="solution"
                />

                <b-row v-if="uploadedImageUrls" class="match-height mt-2">
                  <b-col
                    v-for="imageUrl in uploadedImageUrls"
                    :key="imageUrl"
                    lg="6"
                  >
                    <b-img
                      :src="imageUrl"
                      fluid
                      alt="..."
                      style="height: 240px; object-fit: contain"
                    />
                    <b-form-input
                      readonly
                      :value="'[[' + imageUrl + ']]'"
                      class="mt-1"
                    />
                  </b-col>
                </b-row>

                <b-form-file
                  id="file-select"
                  class="mt-2"
                  browse-text="Fotograf Seç"
                  v-model="newImage"
                  placeholder="Bir fotograf seçin veya buraya sürükleyin..."
                  accept="image/jpeg, image/jpg, image/png"
                />

                <div v-if="newImage" class="mt-1">
                  <b-card-text class="my-1" style="float: left">
                    Seçili fotograf:
                    <strong>{{ newImage ? newImage.name : "" }}</strong>
                  </b-card-text>
                  <b-button
                    variant="outline-primary"
                    @click="uploadNewImage()"
                    size="sm"
                    class="mt-1"
                    style="float: right"
                  >
                    Yükle
                  </b-button>
                </div>

                <div class="mt-3" style="display: flex">
                  <div class="flex-grow-1"></div>
                  <b-button
                    variant="outline-warning"
                    pill
                    @click="pasteCopiedQuestion()"
                    size="sm"
                  >
                    Kopyalanan Soruyu Yapıştır
                  </b-button>
                </div>
              </b-col>

              <b-col xl="6" cols="12">
                <b-card title="Soru önizleme">
                  <b-card-text style="white-space: pre-wrap">
                    <div
                      v-html="
                        '<strong>Soru:</strong> ' +
                        getHtmlWithImage(questionText)
                      "
                    ></div>
                  </b-card-text>
                  <b-card-text style="white-space: pre-wrap">
                    <p
                      :class="
                        answer == 'A' ? 'font-weight-bolder text-primary' : ''
                      "
                    >
                      <strong>A:</strong> {{ optionA }}
                    </p>
                  </b-card-text>
                  <b-card-text style="white-space: pre-wrap">
                    <p
                      :class="
                        answer == 'B' ? 'font-weight-bolder text-primary' : ''
                      "
                    >
                      <strong>B:</strong> {{ optionB }}
                    </p>
                  </b-card-text>
                  <b-card-text style="white-space: pre-wrap">
                    <p
                      :class="
                        answer == 'C' ? 'font-weight-bolder text-primary' : ''
                      "
                    >
                      <strong>C:</strong> {{ optionC }}
                    </p>
                  </b-card-text>
                  <b-card-text style="white-space: pre-wrap">
                    <p
                      :class="
                        answer == 'D' ? 'font-weight-bolder text-primary' : ''
                      "
                    >
                      <strong>D:</strong> {{ optionD }}
                    </p>
                  </b-card-text>
                  <b-card-text style="white-space: pre-wrap">
                    <p
                      :class="
                        answer == 'E' ? 'font-weight-bolder text-primary' : ''
                      "
                    >
                      <strong>E:</strong> {{ optionE }}
                    </p>
                  </b-card-text>
                  <b-card-text style="white-space: pre-wrap" class="mt-2">
                    <p>
                      <strong>Alan: </strong>
                      {{ toTitleCase(selectedFieldText) }}
                    </p>
                  </b-card-text>

                  <b-card-text class="mt-3" style="white-space: pre-wrap">
                    <div
                      v-html="
                        '<strong>Çözüm / Açıklama</strong> <hr/>' +
                        getHtmlWithImage(solution)
                      "
                    ></div>
                  </b-card-text>

                  <div
                    v-if="selectedQuestion"
                    class="mt-3"
                    style="display: flex; justify-content: flex-end"
                  >
                    <b-button
                      variant="primary"
                      @click="updateSelectedQuestion()"
                      class="mx-1"
                    >
                      Soruyu Düzenle
                    </b-button>
                    <b-button
                      variant="danger"
                      v-b-modal.modal-delete-question
                      class="mx-1"
                    >
                      Soruyu Sil
                    </b-button>
                  </div>
                  <div
                    v-else
                    class="mt-3"
                    style="display: flex; justify-content: flex-end"
                  >
                    <b-button
                      variant="outline-primary"
                      @click="addQuestion(false)"
                      class="mx-1"
                    >
                      Havuza Ekle
                    </b-button>
                    <b-button
                      v-if="selectedLessonExam"
                      variant="primary"
                      @click="addQuestion(true)"
                    >
                      Seçili Denemeye Ekle
                    </b-button>
                  </div>

                  <div
                    v-if="
                      selectedQuestion && selectedQuestion.lesson_exam_id > 0
                    "
                  >
                    <hr />
                    <b-alert
                      v-if="selectedQuestion.canceled"
                      variant="warning"
                      show
                      class="mt-2"
                    >
                      <h4 class="alert-heading">İptal Açıklaması</h4>
                      <div class="alert-body">
                        {{ selectedQuestion.cancel_description }}
                      </div>
                    </b-alert>
                    <div style="display: flex; justify-content: flex-end">
                      <b-button
                        variant="outline-warning"
                        @click="transferQuestionToPool()"
                        class="mx-1 mt-1"
                      >
                        Havuza Aktar
                      </b-button>
                      <b-button
                        v-if="selectedQuestion.canceled"
                        variant="outline-primary"
                        @click="showRemoveQuestionCancelModal()"
                        class="mx-1 mt-1"
                      >
                        İptali Kaldır
                      </b-button>
                      <b-button
                        v-else
                        variant="outline-danger"
                        @click="showCancelQuestionModal()"
                        class="mx-1 mt-1"
                      >
                        Soruyu İptal Et
                      </b-button>
                    </div>
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </div>
        </b-card>

        <div style="display: flex; justify-content: flex-end">
          <b-button
            v-if="selectedLessonExam"
            class="mr-1 mb-1"
            variant="flat-primary"
            @click="$refs['modal-html-tags'].show()"
          >
            HTML Etiketleri
          </b-button>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-delete-question"
      ref="modal-delete-question"
      hide-footer
      centered
      title="Soruyu Sil"
    >
      <p class="m-1">Seçili soru tamamen silinecek. Bu işlem geri alınamaz!</p>

      <hr style="margin: 20px 10px" />
      <div>
        <b-button
          class="mr-1"
          style="float: right"
          variant="primary"
          @click="$refs['modal-delete-question'].hide()"
        >
          Kapat
        </b-button>
        <b-button
          class="mr-1"
          style="float: right"
          variant="danger"
          @click="deleteSelectedQuestion()"
        >
          Sil
        </b-button>
      </div>
    </b-modal>

    <b-modal
      id="modal-html-tags"
      ref="modal-html-tags"
      hide-footer
      centered
      no-close-on-backdrop
      size="lg"
      title="Çözüm alanı için HTML etiketleri"
    >
      <b-alert variant="warning" show class="mt-1">
        <h4 class="alert-heading">Önemli Hatırlatma!</h4>
        <div class="alert-body">
          - Aşağıda listelenen HTML etiketleri yeni satıra geçme sağladıkları
          için metin içerisine eklenirken yeni satıra geçişmeden yan yana
          yazılmalıdır! Daima çıktı ekranı (görünüm) dikkate alınmalıdır.
        </div>
      </b-alert>
      <b-row>
        <b-col md="6" class="mb-1">
          <pre>
            {{ getSampleHtml() }}
          </pre>
        </b-col>
        <b-col md="6" class="mb-1">
          <div v-html="getSampleHtml()" />
        </b-col>
      </b-row>

      <hr style="margin: 20px 10px" />
      <div style="display: flex; justify-content: flex-end">
        <b-button
          class="mr-1 mb-1"
          variant="outline-primary"
          @click="$refs['modal-html-tags'].hide()"
        >
          Kapat
        </b-button>
      </div>
    </b-modal>

    <b-modal
      id="modal-add-lesson-exam"
      ref="modal-add-lesson-exam"
      hide-footer
      centered
      no-close-on-backdrop
      size="lg"
      title="Ders Denemesi ekle"
    >
      <div v-if="lessonExamItem">
        <b-row>
          <b-col lg="6">
            <b-form-group
              label="Ders Seçin"
              label-for="lesson"
              style="margin-top: 5px"
            >
              <v-select
                id="lesson"
                v-model="lessonExamItem.title"
                :clearable="false"
                :options="subjectList"
                label="title"
              >
                <template #option="data">
                  <span> {{ data.title }}</span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <b-form-group
              label="Deneme Sırası"
              label-for="exam_order"
              style="margin-top: 5px"
            >
              <v-select
                id="exam_order"
                v-model="lessonExamItem.exam_order"
                placeholder=""
                :options="[
                  '1',
                  '2',
                  '3',
                  '4',
                  '5',
                  '6',
                  '7',
                  '8',
                  '9',
                  '10',
                  '11',
                  '12',
                  '13',
                  '14',
                  '15',
                  '16',
                  '17',
                  '18',
                  '19',
                  '20',
                  '21',
                  '22',
                  '23',
                  '24',
                  '25',
                  '26',
                  '27',
                  '28',
                  '29',
                  '30',
                ]"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    <strong>{{ search }}</strong> için sonuç bulunamadı.
                  </template>
                </template>
                <template #option="data">
                  <span> {{ data.label }}. deneme</span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col lg="6">
            <b-form-group
              label="Soru Sayısı"
              label-for="question_count"
              style="margin-top: 5px"
            >
              <b-form-input
                id="question_count"
                v-model="lessonExamItem.question_count"
                placeholder="60"
                autocomplete="off"
              />
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <b-form-group
              label="Süre (dk)"
              label-for="duration_in_minutes"
              style="margin-top: 5px"
            >
              <b-form-input
                id="duration_in_minutes"
                v-model="lessonExamItem.duration_in_minutes"
                placeholder="60"
                autocomplete="off"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <hr style="margin: 20px 10px" />
      <div style="display: flex; justify-content: flex-end">
        <b-button
          class="mr-1 mb-1"
          variant="outline-primary"
          @click="addLessonExam()"
        >
          Ekle
        </b-button>
      </div>
    </b-modal>

    <b-modal
      id="modal-update-lesson-exam"
      ref="modal-update-lesson-exam"
      hide-footer
      centered
      no-close-on-backdrop
      size="lg"
      title="Ders Denemesini Güncelle"
    >
      <div v-if="lessonExamItem">
        <b-row>
          <b-col lg="6">
            <b-form-group
              label="Ders Seçin"
              label-for="lesson"
              style="margin-top: 5px"
            >
              <v-select
                id="lesson"
                v-model="lessonExamItem.title"
                :clearable="false"
                :options="subjectList"
                label="title"
              >
                <template #option="data">
                  <span> {{ data.title }}</span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <b-form-group
              label="Deneme Sırası"
              label-for="exam_order"
              style="margin-top: 5px"
            >
              <v-select
                id="exam_order"
                v-model="lessonExamItem.exam_order"
                placeholder=""
                :options="[
                  '1',
                  '2',
                  '3',
                  '4',
                  '5',
                  '6',
                  '7',
                  '8',
                  '9',
                  '10',
                  '11',
                  '12',
                  '13',
                  '14',
                  '15',
                  '16',
                  '17',
                  '18',
                  '19',
                  '20',
                  '21',
                  '22',
                  '23',
                  '24',
                  '25',
                  '26',
                  '27',
                  '28',
                  '29',
                  '30',
                ]"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    <strong>{{ search }}</strong> için sonuç bulunamadı.
                  </template>
                </template>
                <template #option="data">
                  <span> {{ data.label }}. deneme</span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6">
            <b-form-group
              label="Soru Sayısı"
              label-for="question_count"
              style="margin-top: 5px"
            >
              <b-form-input
                id="question_count"
                v-model="lessonExamItem.question_count"
                placeholder="60"
                autocomplete="off"
              />
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <b-form-group
              label="Süre (dk)"
              label-for="duration_in_minutes"
              style="margin-top: 5px"
            >
              <b-form-input
                id="duration_in_minutes"
                v-model="lessonExamItem.duration_in_minutes"
                placeholder="60"
                autocomplete="off"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-checkbox v-model="lessonExamItem.published" plain>
          Denemeyi yayına al
        </b-form-checkbox>
      </div>
      <hr style="margin: 20px 10px" />
      <div style="display: flex; justify-content: flex-end">
        <b-button
          class="mr-1 mb-1"
          variant="outline-primary"
          @click="updateLessonExam()"
        >
          Güncelle
        </b-button>
      </div>
    </b-modal>

    <b-modal
      id="modal-delete-lesson-exam"
      ref="modal-delete-lesson-exam"
      hide-footer
      centered
      no-close-on-backdrop
      title="Ders Denemesini Sil"
    >
      <div v-if="selectedLessonExam" style="margin: 30px">
        <p>
          {{ selectedLessonExam.title }} denemesi silinecek. Bu işlem geri
          alınamaz!
        </p>
      </div>
      <hr style="margin: 20px 10px" />
      <div style="display: flex; justify-content: flex-end">
        <b-button
          class="mr-1 mb-1"
          variant="outline-primary"
          @click="$refs['modal-delete-lesson-exam'].hide()"
        >
          İptal
        </b-button>

        <b-button
          class="mr-1 mb-1"
          variant="outline-danger"
          @click="deleteLessonExam()"
        >
          Sil
        </b-button>
      </div>
    </b-modal>

    <b-modal
      ref="modal-cancel-question"
      hide-footer
      centered
      title="Soruyu İptal Et"
    >
      <p>
        Soru iptal edilecek ve kullanıcıların puanları tekrar hesaplanacak.
        <br />
        İptal ettiğiniz soru denemeye sonradan girenlerde deneme esnasında
        normal görünmekte ve deneme sonucunda herkes için sorunun iptal edildiği
        belirtilmektedir.
        <br />
        Soruda değişiklik/düzeltme yaptıysanız lütfen açıklama kısmında bunu
        kullanıcılara belirtin.
      </p>
      <label class="mt-1" for="solution-text"
        >İptal sebibi açıklaması (maks 2-3 satır)</label
      >
      <b-form-textarea
        id="solution-text"
        placeholder="Zorunlu alan"
        rows="2"
        max-rows="8"
        class="mb-1 mb-xl-0"
        v-model="cancelDescription"
      />
      <hr style="margin: 20px 10px" />
      <div>
        <b-button
          class="mr-1"
          style="float: right"
          variant="primary"
          @click="$refs['modal-cancel-question'].hide()"
        >
          Kapat
        </b-button>
        <b-button
          class="mr-1"
          style="float: right"
          variant="warning"
          @click="cancelQuestion()"
        >
          İptal Et
        </b-button>
      </div>
    </b-modal>

    <b-modal
      ref="modal-remove-cancel"
      hide-footer
      centered
      title="Soru İptalini Kaldır"
    >
      <p class="m-1">
        Soru iptali kaldırılacak ve kullanıcıların puanları verdikleri cevaplar
        üzerinden tekrar hesaplanacak.
      </p>
      <hr style="margin: 20px 10px" />
      <div>
        <b-button
          class="mr-1"
          style="float: right"
          variant="primary"
          @click="$refs['modal-remove-cancel'].hide()"
        >
          Kapat
        </b-button>
        <b-button
          class="mr-1"
          style="float: right"
          variant="danger"
          @click="removeQuestionCancel()"
        >
          Kaldır
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import store from "@/store/index";
import {
  BCard,
  BCardText,
  BTable,
  BSpinner,
  BPagination,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BButton,
  BFormCheckbox,
  BFormFile,
  BFormTextarea,
  BTabs,
  BTab,
  BImg,
  BAlert,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import LessonExamResult from "@/views/admin/AdminLessonExamResult.vue";

export default {
  components: {
    BCard,
    BCardText,
    BTable,
    BSpinner,
    BPagination,
    BRow,
    BCol,
    BFormCheckbox,
    BFormInput,
    BFormGroup,
    flatPickr,
    BButton,
    BFormFile,
    BFormTextarea,
    vSelect,
    BTabs,
    BTab,
    BImg,
    BAlert,
    BBadge,
    LessonExamResult,
  },
  data() {
    return {
      loading: true,
      //
      lessonExamForResults: null,
      //
      lessonExamsFilter: "",
      //
      examsCurrentPage: 1,
      examsPerPage: 10,
      lessonExams: [],
      selectedLessonExam: null,
      lessonExamsTableFields: [
        { key: "title", label: "Ders", sortable: true },
        { key: "exam_order", label: "Sınav Sırası", sortable: true },
        { key: "question_count", label: "Soru Sayısı" },
        { key: "duration_in_minutes", label: "Süre" },
        { key: "published", label: "Yayınlanma", sortable: true },
        {
          key: "participant_count",
          label: "Tamamlayan Kişi Sayısı",
          sortable: true,
        },
        { key: "creation_time", label: "Oluşturulma Tarihi", sortable: true },
        { key: "action", label: "İşlemler" },
      ],
      lessonExamItem: {
        title: null,
        exam_order: 1,
        question_count: null,
        duration_in_minutes: null,
        published: false,
      },
      //
      questionsOfExam: [],
      questionsOfExamCurrentPage: 1,
      questionsOfExamTableFields: [
        { key: "order_in_exam", label: "Sıra", sortable: true },
        { key: "title", label: "Ders" },
        { key: "subtitle", label: "Konu" },
        { key: "question_text", label: "Soru" },
      ],
      //
      tusFields: [],
      selectedSubject: null,
      //
      poolQuestions: [],
      //
      questionFilter: "",
      //
      questionPoolTableFields: [
        { key: "order", label: "No" },
        { key: "title", label: "Ders", sortable: true },
        { key: "subtitle", label: "Konu", sortable: true },
        { key: "question_text", label: "Soru" },
        { key: "action", label: "" },
      ],
      questionsCurrentPage: 1,
      questionsPerPage: 5,
      //
      selectedQuestion: null,
      //
      selectedFieldText: null,
      questionText: "",
      optionA: "",
      optionB: "",
      optionC: "",
      optionD: "",
      optionE: "",
      answer: "",
      solution: "",
      newImage: null,
      uploadedImageUrls: [],
      //
      cancelDescription: "",
    };
  },

  computed: {
    adminObject() {
      return JSON.parse(localStorage.getItem("adminObject"));
    },

    subjectList() {
      var subjectList = [];
      this.tusFields.forEach(function (field) {
        if (field.subtitle_id == 0) {
          subjectList.push(field);
        }
      });
      return subjectList;
    },

    filteredLessonExams() {
      var filteredExams = [];
      var contentFilter = !this.lessonExamsFilter
        ? ""
        : this.lessonExamsFilter.toLocaleLowerCase("tr-TR");
      this.lessonExams.forEach((exam) => {
        if (
          contentFilter.length == 0 ||
          exam.title.toLocaleLowerCase("tr-TR").includes(contentFilter)
        ) {
          filteredExams.push(exam);
        }
      });
      return filteredExams;
    },

    questionsInTable() {
      var orderCounter = 1;
      var questionsInTable = [];
      var titleId = this.selectedSubject ? this.selectedSubject.title_id : 0;
      var filter = this.questionFilter.toLocaleLowerCase("tr-TR");
      this.poolQuestions.forEach(function (question) {
        if (question.title_id == titleId || titleId == 0) {
          var allText =
            question.question_text +
            " " +
            question.option_a +
            " " +
            question.option_b +
            " " +
            question.option_c +
            " " +
            question.option_d +
            " " +
            question.option_e +
            " " +
            question.solution;
          if (!filter || allText.toLocaleLowerCase("tr-TR").includes(filter)) {
            questionsInTable.push(question);
          }
        }
      });
      questionsInTable.forEach(function (question) {
        question["order"] = orderCounter++;
      });
      return questionsInTable;
    },

    tusFieldOptions() {
      var options = [];
      this.tusFields.forEach(function (field) {
        if (field.subtitle_id > 0) {
          options.push(field.title + " -> " + field.subtitle);
        } else {
          options.push(field.title);
        }
      });
      return options;
    },
  },

  watch: {
    selectedLessonExam: function () {
      this.loadQuestions();
    },
  },

  methods: {
    showExamResult(data) {
      this.lessonExamForResults = data.item;
    },

    showCancelQuestionModal() {
      this.$refs["modal-cancel-question"].show();
    },

    async cancelQuestion() {
      this.$refs["modal-cancel-question"].hide();
      if (!this.cancelDescription || this.cancelDescription.length < 10) {
        this.showError("Lütfen açıklama girin!");
        return;
      }
      var payload = {
        questionId: this.selectedQuestion.leq_id,
        cancelDescription: this.cancelDescription,
      };
      var response = await store.dispatch(
        "adminLessonExams/cancelQuestion",
        payload
      );
      if (response) {
        this.selectedQuestion.canceled = true;
        this.selectedQuestion.cancel_description = this.cancelDescription;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "İşlem Başarılı!",
            icon: "BellIcon",
            text: "Soru iptal edildi.",
            variant: "success",
          },
        });
        this.$forceUpdate();
      } else {
        this.showError("Oluşan bir hatadan dolayı işlem gerçekleştirilemedi.");
      }
    },

    showRemoveQuestionCancelModal() {
      this.$refs["modal-remove-cancel"].show();
    },

    async removeQuestionCancel() {
      this.$refs["modal-remove-cancel"].hide();
      var response = await store.dispatch(
        "adminLessonExams/removeQuestionCancel",
        this.selectedQuestion.leq_id
      );
      if (response) {
        this.selectedQuestion.canceled = false;
        this.selectedQuestion.cancel_description = null;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "İşlem Başarılı!",
            icon: "BellIcon",
            text: "Soru iptali kaldırıldı.",
            variant: "success",
          },
        });
        this.$forceUpdate();
      } else {
        this.showError("Oluşan bir hatadan dolayı işlem gerçekleştirilemedi.");
      }
    },

    showError(errorMessage) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Uyarı!",
          icon: "BellIcon",
          text: errorMessage,
          variant: "danger",
        },
      });
    },

    async transferQuestionToLessonExamFromPool(question) {
      if (this.selectedLessonExam && question) {
        if (question.title_id != this.selectedLessonExam.title_id) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Opps!",
              icon: "BellIcon",
              text: "Farklı bir derse ait soru ekleyemezsiniz!",
              variant: "danger",
            },
          });
          return;
        }
        var payload = {
          question_id: question.pq_id,
          lesson_exam_id: this.selectedLessonExam.le_id,
        };
        var transfered = await store.dispatch(
          "adminLessonExams/transferQuestionToLessonExamFromPool",
          payload
        );
        if (transfered) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "İşlem Başarılı!",
              icon: "BellIcon",
              text:
                "Seçtiğiniz soru " +
                this.toTitleCase(this.selectedLessonExam.title) +
                " denemesine eklendi.",
              variant: "success",
            },
          });
          this.selectedLessonExam.current_question_count++;
          this.questionsOfExam = await store.dispatch(
            "adminLessonExams/getQuestionsOfLessonExam",
            this.selectedLessonExam.le_id
          );
          var questList = [];
          for (var quest of this.poolQuestions) {
            if (quest.pq_id != question.pq_id) {
              questList.push(quest);
            }
          }
          this.poolQuestions = questList;
          this.poolQuestions = await store.dispatch(
            "adminLessonExams/getPoolQuestions"
          );
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Uyarı!",
              icon: "BellIcon",
              text: "Bir sorun oluştu ve soru taşınamadı!",
              variant: "danger",
            },
          });
        }
      }
    },

    async transferQuestionToPool() {
      if (this.selectedLessonExam && this.selectedQuestion) {
        var payload = {
          question_id: this.selectedQuestion.leq_id,
          lesson_exam_id: this.selectedLessonExam.le_id,
        };
        var transfered = await store.dispatch(
          "adminLessonExams/transferQuestionToPool",
          payload
        );
        if (transfered) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "İşlem Başarılı!",
              icon: "BellIcon",
              text: "Seçtiğiniz soru havuza aktarıldı.",
              variant: "success",
            },
          });
          this.cancelQuestionEdit();
          this.selectedLessonExam.current_question_count--;
          this.questionsOfExam = await store.dispatch(
            "adminLessonExams/getQuestionsOfLessonExam",
            this.selectedLessonExam.le_id
          );
          this.poolQuestions = await store.dispatch(
            "adminLessonExams/getPoolQuestions"
          );
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Uyarı!",
              icon: "BellIcon",
              text: "Bir sorun oluştu ve soru kaldırılamadı!",
              variant: "danger",
            },
          });
        }
      }
    },

    async loadQuestions() {
      if (this.selectedLessonExam) {
        this.questionsOfExam = await store.dispatch(
          "adminLessonExams/getQuestionsOfLessonExam",
          this.selectedLessonExam.le_id
        );
      } else {
        this.poolQuestions = await store.dispatch(
          "adminLessonExams/getPoolQuestions"
        );
      }
    },
    onLessonExamSelected(items) {
      this.questionsOfExamCurrentPage = 1;
      this.questionsCurrentPage = 1;
      if (!items || items.length == 0) {
        this.selectedLessonExam = null;
        this.selectedQuestion = null;
      } else {
        this.selectedLessonExam = items[0];
        var self = this;
        this.subjectList.forEach((subj) => {
          if (subj.title_id == self.selectedLessonExam.title_id) {
            self.selectedSubject = subj;
          }
        });
      }
    },

    showAddLessonExamModal() {
      this.lessonExamItem = {
        title: null,
        exam_order: 1,
        question_count: null,
        duration_in_minutes: null,
        published: false,
      };
      this.$refs["modal-add-lesson-exam"].show();
    },
    async addLessonExam() {
      var title = this.lessonExamItem.title;
      var examOrder = this.lessonExamItem.exam_order;
      var questionCount = this.lessonExamItem.question_count;
      var duration = this.lessonExamItem.duration_in_minutes;

      if (
        title == null ||
        !this.isInt(examOrder) ||
        !this.isInt(questionCount) ||
        !this.isInt(duration)
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Uyarı!",
            icon: "BellIcon",
            text: "Eksik veya hatalı bilgi girdiniz!",
            variant: "danger",
          },
        });
        return;
      }

      var payload = {
        title_id: title.title_id,
        exam_order: examOrder,
        question_count: questionCount,
        duration_in_minutes: duration,
      };
      var added = await store.dispatch(
        "adminLessonExams/addLessonExam",
        payload
      );

      if (added) {
        this.lessonExams = await store.dispatch(
          "adminLessonExams/getLessonExams"
        );
        this.$refs["modal-add-lesson-exam"].hide();
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Uyarı!",
            icon: "BellIcon",
            text: "Bir sorun oluştu ve sınav eklenemedi!",
            variant: "danger",
          },
        });
      }
    },

    showUpdateLessonExamModal(exam) {
      this.selectedLessonExam = exam;
      var currentTitle = null;
      this.subjectList.forEach((lesson) => {
        if (lesson.title == exam.title) {
          currentTitle = lesson;
        }
      });
      this.lessonExamItem = {
        title: currentTitle,
        exam_order: exam.exam_order,
        question_count: exam.question_count,
        duration_in_minutes: exam.duration_in_minutes,
        published: exam.published ? true : false,
      };
      this.$refs["modal-update-lesson-exam"].show();
    },

    async updateLessonExam() {
      var title = this.lessonExamItem.title;
      var examOrder = this.lessonExamItem.exam_order;
      var questionCount = this.lessonExamItem.question_count;
      var duration = this.lessonExamItem.duration_in_minutes;
      var published = this.lessonExamItem.published ? 1 : 0;
      if (
        title == null ||
        !this.isInt(examOrder) ||
        !this.isInt(questionCount) ||
        !this.isInt(duration)
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Uyarı!",
            icon: "BellIcon",
            text: "Eksik veya hatalı bilgi girdiniz!",
            variant: "danger",
          },
        });
        return;
      }
      if (
        published &&
        this.selectedLessonExam.current_question_count < questionCount
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Uyarı!",
            icon: "BellIcon",
            text: "Soru sayısını tamamlamadan denemeyi yayınlayamazsınız!",
            variant: "danger",
          },
        });
        return;
      }

      var payload = {
        lesson_exam_id: this.selectedLessonExam.le_id,
        title_id: title.title_id,
        exam_order: examOrder,
        question_count: questionCount,
        duration_in_minutes: duration,
        published: published,
      };
      var updated = await store.dispatch(
        "adminLessonExams/updateLessonExam",
        payload
      );

      if (updated) {
        this.lessonExams = await store.dispatch(
          "adminLessonExams/getLessonExams"
        );
        this.$refs["modal-update-lesson-exam"].hide();
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Uyarı!",
            icon: "BellIcon",
            text: "Bir sorun oluştu ve sınav güncellenemedi!",
            variant: "danger",
          },
        });
      }
    },

    showDeleteLessonExamModal(exam) {
      this.selectedLessonExam = exam;
      this.$refs["modal-delete-lesson-exam"].show();
    },

    async deleteLessonExam() {
      var lessonExamId = this.selectedLessonExam.le_id;
      this.selectedLessonExam = null;
      this.$refs["modal-delete-lesson-exam"].hide();
      var deleted = await store.dispatch(
        "adminLessonExams/deleteLessonExam",
        lessonExamId
      );
      if (deleted) {
        this.lessonExams = await store.dispatch(
          "adminLessonExams/getLessonExams"
        );
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Uyarı!",
            icon: "BellIcon",
            text: "Bir sorun oluştu ve sınav silinemedi!",
            variant: "danger",
          },
        });
      }
    },

    isInt(value) {
      return (
        !isNaN(value) &&
        parseInt(Number(value)) == value &&
        !isNaN(parseInt(value, 10)) &&
        !(value + "").includes(".") &&
        !(value + "").includes(",")
      );
    },

    getTitleQuestionCount(titleId) {
      var count = 0;
      this.poolQuestions.forEach(function (question) {
        if (question.title_id == titleId || titleId == 0) {
          count++;
        }
      });
      return count;
    },

    onQuestionSelected(questions) {
      this.selectedQuestion = questions[0];
      if (this.selectedQuestion) {
        if (this.selectedQuestion.pq_id > 0) {
          if (this.$refs.examQuestionsTable) {
            this.$refs.examQuestionsTable.clearSelected();
          }
        } else {
          this.$refs.poolQuestionsTable.clearSelected();
        }
        this.questionText = this.selectedQuestion.question_text;
        this.optionA = this.selectedQuestion.option_a;
        this.optionB = this.selectedQuestion.option_b;
        this.optionC = this.selectedQuestion.option_c;
        this.optionD = this.selectedQuestion.option_d;
        this.optionE = this.selectedQuestion.option_e;
        this.answer = this.selectedQuestion.answer;
        this.solution = this.selectedQuestion.solution;
        this.uploadedImageUrls = [];

        this.selectedFieldText =
          this.selectedQuestion.title + " -> " + this.selectedQuestion.subtitle;
      } else {
        if (this.$refs.examQuestionsTable) {
          this.$refs.examQuestionsTable.clearSelected();
        }
        this.$refs.poolQuestionsTable.clearSelected();
        this.questionText = "";
        this.optionA = "";
        this.optionB = "";
        this.optionC = "";
        this.optionD = "";
        this.optionE = "";
        this.answer = "";
        this.solution = "";
        this.uploadedImageUrls = [];
      }
    },

    cancelQuestionEdit() {
      this.selectedQuestion = null;
      this.questionText = "";
      this.optionA = "";
      this.optionB = "";
      this.optionC = "";
      this.optionD = "";
      this.optionE = "";
      this.answer = "";
      this.solution = "";
      this.uploadedImageUrls = [];
    },

    getTrimmedText(text) {
      if (!text) {
        return "";
      }
      return text.trim();
    },

    async addQuestion(toSelectedExam) {
      this.questionText = this.getTrimmedText(this.questionText);
      this.optionA = this.getTrimmedText(this.optionA);
      this.optionB = this.getTrimmedText(this.optionB);
      this.optionC = this.getTrimmedText(this.optionC);
      this.optionD = this.getTrimmedText(this.optionD);
      this.optionE = this.getTrimmedText(this.optionE);
      this.answer = this.getTrimmedText(this.answer);
      this.solution = this.getTrimmedText(this.solution);

      if (
        !this.selectedFieldText ||
        !this.questionText ||
        !this.optionA ||
        !this.optionB ||
        !this.optionC ||
        !this.optionD ||
        !this.optionE ||
        !this.answer
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Eksik Var!",
            icon: "BellIcon",
            text: "Lütfen tüm zorunlu alanları doldurup doğru seçeneği işaretleyin!",
            variant: "danger",
          },
        });
        return;
      }

      if (
        this.optionA == this.optionB ||
        this.optionA == this.optionC ||
        this.optionA == this.optionD ||
        this.optionA == this.optionE ||
        this.optionB == this.optionC ||
        this.optionB == this.optionD ||
        this.optionB == this.optionE ||
        this.optionC == this.optionD ||
        this.optionC == this.optionE ||
        this.optionD == this.optionE
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Aynı seçenek var!",
            icon: "BellIcon",
            text: "Aynı seçenekten birden fazla eklediniz!",
            variant: "danger",
          },
        });
        return;
      }

      var self = this;
      var titleId = -1;
      var subtitleId = -1;
      this.tusFields.forEach(function (field) {
        if (
          self.selectedFieldText == field.title + " -> " + field.subtitle ||
          self.selectedFieldText == field.title + field.subtitle
        ) {
          titleId = field.title_id;
          subtitleId = field.subtitle_id;
        }
      });

      if (titleId < 0 || subtitleId < 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Hay aksi!",
            icon: "BellIcon",
            text: "Alan tespit edilemedi!",
            variant: "danger",
          },
        });
        return;
      }

      if (toSelectedExam && titleId != this.selectedLessonExam.title_id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Opps!",
            icon: "BellIcon",
            text: "Farklı bir derse ait soru ekleyemezsiniz!",
            variant: "danger",
          },
        });
        return;
      }

      var added = false;

      if (toSelectedExam) {
        var payload = {
          questionText: this.questionText,
          lessonExamId: this.selectedLessonExam.le_id,
          optionA: this.optionA,
          optionB: this.optionB,
          optionC: this.optionC,
          optionD: this.optionD,
          optionE: this.optionE,
          answer: this.answer,
          solution: this.solution,
          titleId: titleId,
          subtitleId: subtitleId,
        };
        added = await store.dispatch(
          "adminLessonExams/addQuestionToExam",
          payload
        );
      } else {
        var pyld = {
          questionText: this.questionText,
          optionA: this.optionA,
          optionB: this.optionB,
          optionC: this.optionC,
          optionD: this.optionD,
          optionE: this.optionE,
          answer: this.answer,
          solution: this.solution,
          titleId: titleId,
          subtitleId: subtitleId,
        };
        added = await store.dispatch(
          "adminLessonExams/addQuestionToPool",
          pyld
        );
      }

      if (added) {
        this.questionText = "";
        this.optionA = "";
        this.optionB = "";
        this.optionC = "";
        this.optionD = "";
        this.optionE = "";
        this.answer = "";
        this.solution = "";
        this.selectedFieldText = "";
        this.uploadedImageUrls = [];

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "İşlem Başarılı",
            icon: "BellIcon",
            text: toSelectedExam
              ? "Yazdığınız soru denemeye eklendi"
              : "Yazdığınız soru veritabanına eklendi.",
            variant: "success",
          },
        });

        if (toSelectedExam) {
          this.selectedLessonExam.current_question_count++;
          this.questionsOfExam = await store.dispatch(
            "adminLessonExams/getQuestionsOfLessonExam",
            this.selectedLessonExam.le_id
          );
        } else {
          this.poolQuestions = await store.dispatch(
            "adminLessonExams/getPoolQuestions"
          );
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Hay aksi!",
            icon: "BellIcon",
            text: "Oluşan bir hatadan dolayı soru eklenemedi!",
            variant: "danger",
          },
        });
      }
    },

    async updateSelectedQuestion() {
      this.questionText = this.getTrimmedText(this.questionText);
      this.optionA = this.getTrimmedText(this.optionA);
      this.optionB = this.getTrimmedText(this.optionB);
      this.optionC = this.getTrimmedText(this.optionC);
      this.optionD = this.getTrimmedText(this.optionD);
      this.optionE = this.getTrimmedText(this.optionE);
      this.answer = this.getTrimmedText(this.answer);
      this.solution = this.getTrimmedText(this.solution);
      if (
        !this.selectedFieldText ||
        !this.questionText ||
        !this.optionA ||
        !this.optionB ||
        !this.optionC ||
        !this.optionD ||
        !this.optionE ||
        !this.answer
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Eksik Var!",
            icon: "BellIcon",
            text: "Lütfen tüm zorunlu alanları doldurup doğru seçeneği işaretleyin!",
            variant: "danger",
          },
        });
        return;
      }

      if (
        this.optionA == this.optionB ||
        this.optionA == this.optionC ||
        this.optionA == this.optionD ||
        this.optionA == this.optionE ||
        this.optionB == this.optionC ||
        this.optionB == this.optionD ||
        this.optionB == this.optionE ||
        this.optionC == this.optionD ||
        this.optionC == this.optionE ||
        this.optionD == this.optionE
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Aynı seçenek var!",
            icon: "BellIcon",
            text: "Aynı seçenekten birden fazla eklediniz!",
            variant: "danger",
          },
        });
        return;
      }

      let self = this;

      var titleId = -1;
      var subtitleId = -1;
      this.tusFields.forEach(function (field) {
        if (
          self.selectedFieldText == field.title + " -> " + field.subtitle ||
          self.selectedFieldText == field.title + field.subtitle
        ) {
          titleId = field.title_id;
          subtitleId = field.subtitle_id;
        }
      });

      if (titleId < 0 || subtitleId < 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Hay aksi!",
            icon: "BellIcon",
            text: "Alan tespit edilemedi!",
            variant: "danger",
          },
        });
        return;
      }

      var payload = {
        questionId:
          this.selectedQuestion.leq_id > 0
            ? this.selectedQuestion.leq_id
            : this.selectedQuestion.pq_id,
        questionText: this.questionText,
        optionA: this.optionA,
        optionB: this.optionB,
        optionC: this.optionC,
        optionD: this.optionD,
        optionE: this.optionE,
        answer: this.answer,
        solution: this.solution,
        titleId: titleId,
        subtitleId: subtitleId,
      };

      var updated = false;
      if (this.selectedQuestion.leq_id > 0) {
        updated = await store.dispatch(
          "adminLessonExams/updateExamQuestion",
          payload
        );
      } else {
        updated = await store.dispatch(
          "adminLessonExams/updatePoolQuestion",
          payload
        );
      }
      if (updated) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "İşlem Başarılı",
            icon: "BellIcon",
            text: "Soru güncellendi.",
            variant: "success",
          },
        });
        this.selectedQuestion.question_text = this.questionText;
        this.selectedQuestion.option_a = this.optionA;
        this.selectedQuestion.option_b = this.optionB;
        this.selectedQuestion.option_c = this.optionC;
        this.selectedQuestion.option_d = this.optionD;
        this.selectedQuestion.option_e = this.optionE;
        this.selectedQuestion.answer = this.answer;
        this.selectedQuestion.solution = this.solution;
        this.selectedQuestion.option_e = this.optionE;
        this.tusFields.forEach(function (field) {
          if (
            self.selectedFieldText == field.title + " -> " + field.subtitle ||
            self.selectedFieldText == field.title + field.subtitle
          ) {
            self.selectedQuestion.title_id = field.title_id;
            self.selectedQuestion.title = field.title;
            self.selectedQuestion.subtitle_id = field.subtitle_id;
            self.selectedQuestion.subtitle = field.subtitle;
          }
        });
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Hay aksi!",
            icon: "BellIcon",
            text: "Oluşan bir hatadan dolayı soru güncellenemedi!",
            variant: "danger",
          },
        });
      }
    },

    uploadNewImage() {
      var file = this.newImage;
      if (file) {
        if (file.size > 5 * 1024 * 1024) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Uyarı!",
              icon: "BellIcon",
              text: "Seçilen dosya boyutu en fazla 5 MB olmalıdır!",
              variant: "danger",
            },
          });
          return;
        }
        if (
          !(
            file.type == "image/jpeg" ||
            file.type == "image/jpg" ||
            file.type == "image/png"
          )
        ) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Hatalı format!",
              icon: "BellIcon",
              text: "Kabul edilen dosya formatı: JPG, JPEG, PNG",
              variant: "danger",
            },
          });
          return;
        }

        let self = this;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (event) {
          var imageBase64 = reader.result;
          store
            .dispatch("adminLessonExams/uploadImage", imageBase64)
            .then((response) => {
              if (response) {
                self.newImage = null;
                self.uploadedImageUrls.push(response);
                self.$bvToast.toast("", {
                  title: `Fotograf Yüklendi`,
                  variant: `success`,
                  solid: true,
                });
              } else {
                self.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Hata oluştu!",
                    icon: "BellIcon",
                    text: "Oluşan bir sorundan dolayı yükleme işlemi gerçekleştirilemedi.",
                    variant: "danger",
                  },
                });
              }
            });
        };
        reader.onerror = function (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Hay Aksi!",
              icon: "BellIcon",
              text: "Bilinmeyen bir hata oluştu",
              variant: "danger",
            },
          });
        };
      }
    },

    async deleteSelectedQuestion() {
      this.$refs["modal-delete-question"].hide();

      var deleted = false;
      if (this.selectedQuestion.leq_id) {
        deleted = await store.dispatch(
          "adminLessonExams/deleteLessonExamQuestion",
          this.selectedQuestion.leq_id
        );
      } else {
        deleted = await store.dispatch(
          "adminLessonExams/deletePoolQuestion",
          this.selectedQuestion.pq_id
        );
      }
      if (deleted) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "İşlem Başarılı",
            icon: "BellIcon",
            text: "Seçili soru silindi.",
            variant: "success",
          },
        });

        if (this.selectedQuestion.leq_id) {
          this.questionsOfExam = await store.dispatch(
            "adminLessonExams/getQuestionsOfLessonExam",
            this.selectedLessonExam.le_id
          );
        } else {
          var newList = [];
          for (var question of this.poolQuestions) {
            if (question.pq_id != this.selectedQuestion.pq_id) {
              newList.push(question);
            }
          }
          this.poolQuestions = newList;
        }

        this.selectedQuestion = null;
        this.questionText = "";
        this.optionA = "";
        this.optionB = "";
        this.optionC = "";
        this.optionD = "";
        this.optionE = "";
        this.answer = "";
        this.solution = "";
        this.selectedFieldText = "";
        this.uploadedImageUrls = [];
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Hay aksi!",
            icon: "BellIcon",
            text: "Oluşan bir sorundan dolayı soru silinemedi!",
            variant: "danger",
          },
        });
      }
    },
    toTitleCase(str) {
      return !str
        ? ""
        : str
            .toLocaleLowerCase("tr-TR")
            .replace(
              /(?:^|\s|,|;|!|:|-|\.|\?)[a-z0-9ğçşüöı]/g,
              function (match) {
                return match.toLocaleUpperCase("tr-TR");
              }
            );
    },

    filterBy(option, label, search) {
      return (
        (label || "")
          .toLocaleLowerCase("tr-TR")
          .indexOf(search.toLocaleLowerCase("tr-TR")) > -1
      );
    },

    getHtmlWithImage(text) {
      if (!text) {
        return "";
      }
      if (text.toLocaleLowerCase("tr-TR").includes("script")) {
        return "Error!";
      }
      return text
        .replaceAll("[[", "<img src='")
        .replaceAll(
          "]]",
          "' style='margin: 0px auto; max-width: 96%; display: block; max-height: 640px;'/>"
        );
    },

    getSampleHtml() {
      return `
<b>Kalın Yazı</b>
<br/>
<i>İtalik Yazı</i>

<br/>1. satır<br/>2. satır<br/>

Sırasız Liste
<ul>
  <li>Lorem</li>
  <li>ipsum</li>
  <li>dolor</li>
  <li>sit</li>
</ul>

Sıralı Liste
<ol>
  <li>lorem</li>
  <li>ipsum</li>
  <li>dolor</li>
  <li>sit</li>
</ol>

<h1>Başlık</h1>
<h2>Başlık</h2>
<h3>Başlık</h3>
<h4>Başlık</h4>
<h5>Başlık</h5>

<a href="https://tusbuddy.com">bağlantı metni</a>
`;
    },

    async pasteCopiedQuestion() {
      const copiedText = await navigator.clipboard.readText();
      if (copiedText && copiedText.includes("question_text")) {
        const questionJson = JSON.parse(copiedText);
        if (questionJson) {
          this.questionText = questionJson.question_text;
          this.optionA = questionJson.option_a;
          this.optionB = questionJson.option_b;
          this.optionC = questionJson.option_c;
          this.optionD = questionJson.option_d;
          this.optionE = questionJson.option_e;
          this.solution = questionJson.solution;
          var category = "";
          if (questionJson.subtitle_id > 0) {
            category = questionJson.title + " -> " + questionJson.subtitle;
          } else {
            category = questionJson.title;
          }
          if (this.tusFieldOptions.includes(category)) {
            this.selectedFieldText = category;
          }
          return;
        }
      }
      this.showError("Kopyalanmış soru bulunamadı!");
    },
    dateTimeToDayText(dateTime) {
      if (dateTime && dateTime.trim().length == 19) {
        dateTime = dateTime.trim();
        var date = dateTime.split(" ")[0];
        var year = date.split("-")[0];
        var month = date.split("-")[1];
        var day = date.split("-")[2];
        switch (month) {
          case "01":
            return day + " Ocak " + year;
          case "02":
            return day + " Şubat " + year;
          case "03":
            return day + " Mart " + year;
          case "04":
            return day + " Nisan " + year;
          case "05":
            return day + " Mayıs " + year;
          case "06":
            return day + " Haziran " + year;
          case "07":
            return day + " Temmuz " + year;
          case "08":
            return day + " Ağustos " + year;
          case "09":
            return day + " Eylül " + year;
          case "10":
            return day + " Ekim " + year;
          case "11":
            return day + " Kasım " + year;
          case "12":
            return day + " Aralık " + year;
        }
      }
      return "-";
    },
  },

  async created() {
    let moduleId = "course_exams";
    let moduleName = "Ders Denemeleri"; 
    if(JSON.parse(localStorage.getItem("adminObject"))[moduleId] != 1){
      this.$router.push("/erisim-kapali?moduleName="+moduleName);
      return; 
    } 

    this.tusFields = await store.dispatch("adminLessonExams/getTusFields");
    var subjectList = this.subjectList;
    if (subjectList) {
      this.selectedSubject = subjectList[0];
      this.poolQuestions = await store.dispatch(
        "adminLessonExams/getPoolQuestions"
      );
    }
    this.lessonExams = await store.dispatch("adminLessonExams/getLessonExams");
    this.loading = false;
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.max-two-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.max-one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}
</style>
